import "../assets/css/singlePageLoadingIndicator.scss";
import {usePromiseTracker} from "react-promise-tracker";
import Loader from "react-loader-spinner";
import React from "react";
import IY2BLogoSpinner from "./IY2BLogoSpinner/IY2BLogoSpinner";

const SinglePageLoadingIndicator = props => {

    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div id="backdropLoadingSinglePage" >
            <div
                style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    padding: "10px"
                }}
            >
                <IY2BLogoSpinner />
            </div>
        </div>
    );

};

export default SinglePageLoadingIndicator;
