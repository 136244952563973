export const PT_BR_LOCALE = {
  locale: 'pt-BR',

  // General
  general: {
    increase: 'Auemntar',
    decrease: 'Diminuir',
    root: 'Raiz',
  },

  // Plot Components
  /** statistic text component */
  statistic: {
    total: 'Total',
  },
  /** conversionTag component */
  conversionTag: {
    label: 'Taxa',
  },
  legend: {},
  tooltip: {},
  slider: {},
  scrollbar: {},

  // Plots
  waterfall: {
    total: 'Total',
  },

};
