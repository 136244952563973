import { combineReducers } from 'redux';
import appConfig from "../framework/reducer/appConfig";
import userSession from "../framework/reducer/userSession";
import {connectRouter} from "connected-react-router";
import transacaoManager from "../framework/reducer/transacaoManager";
import dataProvider from "./dataProvider";
import suite from "../framework/reducer/suite";
import wsDataProvider from "../framework/reducer/wsDataProvider";

import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export default combineReducers({
    router: connectRouter(history),
    appConfig: appConfig,
    userSession: userSession,
    suite: suite,
    wsDataProvider: wsDataProvider,
    transacaoManager: transacaoManager,
    dataProvider: dataProvider
});
