// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBlLLuOlaq2H_-yYDR21EJks4jKEY_iQpI",
    authDomain: "iy2b-portal.firebaseapp.com",
    projectId: "iy2b-portal",
    storageBucket: "iy2b-portal.appspot.com",
    messagingSenderId: "502877877015",
    appId: "1:502877877015:web:a609ecd895c12fa7a07d52",
    measurementId: "G-3ENM0E52EW",
    oauthBackend: "https://iy2b-portal.firebaseapp.com/__/auth/handler"
};

export {
    firebaseConfig
}
