import {Pagination} from "antd";
import React, {useEffect, useState} from "react";
import {useStateRef} from "../../lib/iy2b-react";

const NavegadorRegistro = (props) => {

    const { registros, navegaPara } = props;

    const [posicaoAtual, setPosicaoAtual, refPosicaoAtual] = useStateRef(1);

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                posicaoAtual: () => refPosicaoAtual.current,
                temProximo: () => (refPosicaoAtual.current < registros.length),
                navegaProximo: () => navegaParaRegistro (refPosicaoAtual.current + 1)
            };
        }

        return () => {

        };

    }, []);


    const navegaParaRegistro = (posicao) => {
        setPosicaoAtual(posicao);
        navegaPara (posicao);
    }

    return (
        <Pagination
            defaultCurrent={1}
            total={registros.length}
            defaultPageSize={1}
            hideOnSinglePage={true}
            showSizeChanger={false}
            current={posicaoAtual}
            onChange={navegaParaRegistro}/>
    );
}

export default NavegadorRegistro;
