import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer, { history } from '../reducer';
import StoreListener from "../framework/lib/StoreListener";

const initialState = {};

const enhancers = [];

//// https://apiko.com/blog/redux-thunk-use-case/

const middleware = [
    thunk,
    routerMiddleware(history),
    StoreListener
];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const Store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
);

export default Store;
