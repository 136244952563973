import "./pt-BR"
import "./core"
import "./parser"
import "./sugarpak"

export function dateJSLoader () {

    console.log("dateJSLoader");

    console.log(Date.today());

    return true;

}
