let KEYCODE_Z = 90;
let KEYCODE_Y = 89;

export function FakeEvent (value, checked) {

    return {
        preventDefault: () => { },
        target: {
            value: value||"",
            checked: checked||false
        }
    }
}

export function keyPressPropName () {
    if (typeof navigator !== 'undefined') {
        return navigator.userAgent.match(/Android/i)
            ? 'onBeforeInput'
            : 'onKeyPress';
    }
    return 'onKeyPress';
}

export function isUndo(e) {
    return (
        (e.ctrlKey || e.metaKey) &&
        e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z)
    );
}

export function isRedo(e) {
    return (
        (e.ctrlKey || e.metaKey) &&
        e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y)
    );
}

export function hasSelection(el) {
    const selection = getSelection(el);
    return (selection.start < selection.end);
}

export function getSelection(el)  {
    let start, end;
    if (el.selectionStart !== undefined) {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        try {
            el.focus();
            // @ts-ignore (IE only)
            let rangeEl = el.createTextRange();
            let clone = rangeEl.duplicate();

            // @ts-ignore (IE only)
            rangeEl.moveToBookmark(document.selection.createRange().getBookmark());
            clone.setEndPoint('EndToStart', rangeEl);

            start = clone.text.length;
            end = start + rangeEl.text.length;
        } catch (e) {
            /* not focused or not visible */
        }
    }

    return { start, end };
}

let selectionTimeout = null;

export function setSelection(el, selection) {

    clearTimeout(selectionTimeout);

    try {
        if (
            el.selectionStart === selection.start &&
            el.selectionEnd === selection.end
        ) {
            return;
        }

        if (el.selectionStart !== undefined) {
            el.focus();
            el.setSelectionRange(selection.start, selection.end);

            // fix https://bugs.chromium.org/p/chromium/issues/detail?id=32865
            selectionTimeout = setTimeout(() => {
                setSelection(el, selection);
            }, 0);

        } else {
            el.focus();
            // @ts-ignore (IE only)
            let rangeEl = el.createTextRange();
            rangeEl.collapse(true);
            rangeEl.moveStart('character', selection.start);
            rangeEl.moveEnd('character', selection.end - selection.start);
            rangeEl.select();
        }
    } catch (e) {
        /* not focused or not visible */
    }
}
