const PATH_API = (process.env.NODE_ENV === 'development') ? "/api" : "";

const initialState = {
    host: window.location.protocol + "//" + ((process.env.NODE_ENV === 'development')?window.location.host:process.env.REACT_APP_HOST_BACKEND) + PATH_API,
    versao: process.env.REACT_APP_PACKAGE_VERSION,
    nome: process.env.REACT_APP_NAME,
    txIdApp: process.env.REACT_APP_TX_ID_APP,
    txConfig: process.env.REACT_APP_CONFIG,
    wsToken: process.env.REACT_APP_WSTOKEN,
    cdPessoaEmpWsToken: process.env.REACT_APP_EMPRESA_TOKEN,
    cdPessoaEmpModeloOrig: process.env.REACT_APP_EMPRESA_MODELO,
    cdTransacaoExecAutom: parseInt(process.env.REACT_APP_CDTRANSACAO_EXEC_AUTOMATICA),
    skin: process.env.REACT_APP_SKIN
};

export default (state = initialState, action) => {

    switch (action.type) {

        default:
            return state;

    }

};
