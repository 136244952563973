//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './headerToolbar.scss';

import React, {useImperativeHandle, useRef} from 'react';
import {Col, Row} from "antd";
import PesquisaWS from "./PesquisaWS";
import BotaoWizard from "./BotaoWizard/botaoWizard";
import NotificacoesDropdown from "./NotificacoesDropdown";
import HeaderToolbarLoadingIndicator from "../../../componentes/LoadingIndicator/HeaderToolbarLoadingIndicator";
import BotaoHelp from "./BotaoHelp/botaoHelp";
import UsuarioDropdown from "./UsuarioDropdown/usuarioDropdown";
import PesquisaWSLoadingIndicator from "../../../componentes/LoadingIndicator/PesquisaWSLoadingIndicator";

const HEADER_TOOLBAR_LOADING_INDICATOR_AREA = "header_toolbar-area";

const HeaderToolbar = React.forwardRef((props, ref) => {

    const { myWidth, paneToHelp } = props;

    const currentWidth = parseInt(myWidth.replace("px", ""));

    const chklDropdownApi = useRef();
    const usrDropdownApi = useRef();

    useImperativeHandle(ref, () => {
        return {
            exibeDropdown: () => {
                chklDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdown: () => {
                chklDropdownApi?.current?.escondeDropdown();
            },
            exibeDropdownUsuario: () => {
                usrDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdownUsuario: () => {
                usrDropdownApi?.current?.escondeDropdown();
            },
        };
    });

    return (
        <Row className={"header-toolbar"}>
            <PesquisaWS
                onVerCheckList={(checklist) => {
                    props.onVerCheckList(checklist);
                }}
                onIniciarApresentacao={() => {
                    props.onIniciarApresentacao();
                }}
                opcoesWizard = {props.opcoesWizard}
                onClickWizard = {props.onClickWizard}
                opcoesMenu={props.opcoesMenu}
                ref={chklDropdownApi}
                userSession={props.userSession}
            />
            <Col flex={"220px"} id={"right-icons"} className={"full-height"}>
                <BotaoWizard
                    opcoes = {props.opcoesWizard}
                />
                <BotaoHelp
                    paneToHelp={paneToHelp}
                    onShowHelp = {props.onShowHelp}
                />
                <NotificacoesDropdown />
                <UsuarioDropdown
                    onLogout={props.onLogout}
                    api={usrDropdownApi}
                />
            </Col>
        </Row>
    );

});

export default HeaderToolbar;

export {
    HEADER_TOOLBAR_LOADING_INDICATOR_AREA
}
