import {post, postJSON} from "../framework/lib/RequestAPI";

const INICIALIZA = 'smk/dataProvider/INICIALIZA';
const SET_DATA = 'smk/dataProvider/SET_DATA';

const initialState = {
};

export default function dataProvider (state = initialState, action) {

    let newState = {...state};

    switch (action.type) {

        case INICIALIZA:

            if(action.payload == undefined) {
                return newState;
            }
            if(action.payload.data == undefined) {
                return newState;
            }

            action.payload.data.forEach(item => {


            });

            return newState;

        case SET_DATA:

            if(action.payload.key == "servidoresKajf") {

                newState.servidoresKajf = [];
                newState.servidoresKajf.addFrom(action.payload.data);

            }

            return newState;

        default:
            return state;

    }

};

// Action Creators

export function inicializaDataProvider() {

    return (dispatch, getState) => {

        dispatch({
            type: INICIALIZA,
            payload: {
                data: []
            }
        });

        return Promise.resolve(getState().dataProvider);

    };

}

export function setData(key, data) {

    return (dispatch, getState) => {

        dispatch({
            type: SET_DATA,
            payload: {
                key: key,
                data: data
            }
        });

        return Promise.resolve(getState().dataProvider);

    };

}
