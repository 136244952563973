import axios from "axios";
import {trackPromise} from 'react-promise-tracker';
import {clearSession, setSession} from "../reducer/userSession";
import delay from './delay';
import {useEffect, useState} from "react";
import {trackUnauthorized, transformData} from "./iy2b-react";

function RequestAPI() {

    let userSession = null;
    let appConfig = null;
    let api = null;

    let store = null

    let debug = false;

    let loadingFn = function () { };

    let vDebugLigado = false;

    let cacheProjetos = { };

    const compareKey = ( a, b ) => {
        if ( a.key < b.key ){
            return -1;
        }
        if ( a.key > b.key ){
            return 1;
        }
        return 0;
    }

    return {

        getProjetosUtilizados: () => {

            const resultado = [];

            for(let sistema in cacheProjetos) {

                const regSistema = {
                    key: sistema,
                    label: sistema,
                    children: []
                };

                resultado.push(regSistema);

                for(let projeto in cacheProjetos[sistema]) {
                    regSistema.children.push({
                        key: projeto,
                        label: projeto
                    });
                }

                regSistema.children.sort(compareKey);

            }

            resultado.sort(compareKey);

            return resultado;

        },

        debugLigado: (value) => {

            if(value === null || undefined) return vDebugLigado;

            vDebugLigado = (value === true);

            cacheProjetos = { };

            return vDebugLigado;

        },

        setLoading: function(value) {
            loadingFn = value;
        },

        setDebug: function(value) {
            debug = (value === true);
        },

        newRequestApi: function (fnLoadingValue) {

            const newApi = new RequestAPI();

            newApi.setDebug(false);
            newApi.setStore(store);
            newApi.setAppConfig(appConfig);
            newApi.setSession(userSession);
            newApi.setLoading(fnLoadingValue)

            return newApi;

        },

        // X-XSRF-TOKEN para o access_token

        setStore: function (value) {

            if(store == null) {

                if(debug) console.log(value);

                store = value;

            }

        },

        setAppConfig: function(value) {

            if(debug) console.log(value);

            appConfig = value;

            api = axios.create({
                baseURL: appConfig.host + "/systemakers",
                responseType: 'json',
                useXDomain: true,
                timeout: (60 * 1000) * 5
            });

            // api.defaults.headers.post['Content-Type'] = 'application/json';
            api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

            delete api.defaults.headers.common['X-Requested-With'];

            // api.defaults.useXDomain = true;
            // api.defaults.timeout = 45 * 1000;
            // api.defaults.headers.common['Authorization'] = AUTH_TOKEN;

            api.interceptors.response.use(function (response) {
                if(response["status"]===200) {
                    if(response["statusText"] === "OK" || response["statusText"] === "" || response["statusText"] === "200" || response["statusText"] === undefined) {
                        if(response.config.headers["iy2b-post"] === "S") {
                            if(response.data.mensagens === undefined || response.data.mensagens === null) {
                                const data = response.data;
                                response.data = {
                                    mensagens: [data]
                                };
                            }
                        }
                    }
                }
                return response;
            }, function (rejection) {

                if(rejection.response) {

                    switch (rejection.response.status) {
                        case 401:
                            console.log('login:authExpired');
                            rejection.response.data = {
                                status: 401,
                                statusText: "unauthorized",
                                key: "authExpired",
                                erro: true
                            };
                            break;
                        case 404:
                        case 403:
                            console.log(rejection.response);
                            break;
                        case 408:
                            console.log('login:timeout');
                            console.log(rejection.response);
                            rejection.response.data = {
                                status: 408,
                                statusText: "loginTimeout",
                                key: "loginTimeout",
                                erro: true
                            };
                            break;
                        case 502:
                        case 503:
                            console.log(rejection.response);
                            break;
                    }

                    if(rejection.response.data !== undefined) {

                        if (typeof rejection.response.data === 'string' || rejection.response.data instanceof String) {

                        } else {
                            rejection.response.data.erro = true;
                        }

                    }

                    return Promise.reject(rejection.response);

                } else {

                    return Promise.reject({status:504, statusText: "Network Error !", data:""});

                }

            });

        },

        setSession: function (value) {

            userSession = value;

            if(debug) console.log(userSession);

        },

        refreshSession: async function () {

            try {

                const responseRefresh = await api.post("/oauth/token", {
                    "type": "refresh"
                }, {
                    headers: {
                        "X-XSRF-TOKEN": userSession.token.refresh_token
                    }
                });

                const data = responseRefresh.data;

                if(data.erro !== true) {

                    await store.dispatch( setSession (responseRefresh.data) );

                    return true;

                } else {

                    if(data.key === "redirLogin") {

                        await store.dispatch( clearSession() );

                        console.log("a sessao foi limpa, e o login ?");

                        return false;

                    } else {

                        console.log(data);

                        return false;

                    }

                }

            } catch (error) {

                throw error;

            }

        },

        get: async function(servico) {

            const config = {
                headers: {
                    "X-XSRF-TOKEN": userSession.token.access_token
                }
            };

            try {

                loadingFn(true);

                const response = await api.get(servico, config);

                loadingFn(false);

                const data = response.data;

                if(data.erro !== true) {

                    //console.log(data);

                } else {

                    if(data.key === "invalidSession") {

                        const refreshResp = await this.refreshSession();

                        if(refreshResp === true) {

                            const secondTry = await this.get(servico);

                            return secondTry.data;

                        } else {

                            throw new Error("nao consegui pegar novo token (get)");

                        }

                    }

                }

                return data;

            } catch (error) {

                loadingFn(false);

                throw error;

            }

        },

        postWS: async function({acaoWebAExecutar, acaoAExecutar, sistema, projeto}, payload) {

            const agora = new Date();

            const servico = "/" + sistema + "/rest/" + projeto + "/post/" + acaoWebAExecutar + "/" + acaoAExecutar + "/" + appConfig.txConfig + "/" + appConfig.wsToken + "/endpoint"
                + "?lm=" + agora.getTime();

            payload.txIdApp = appConfig.txIdApp;

            const config = {
                headers: {
                    'Content-Type' : 'application/json',
                    "html5-agent": "S",
                    "iy2b-post": "S"
                }
            };

            try {

                loadingFn(true);

                const response = await api.post(servico, payload, config);

                loadingFn(false);

                const data = response.data;

                if(data.erro !== true) {

                    //console.log(data);

                } else {

                    if(data.key === "invalidSession") {

                        const refreshResp = await this.refreshSession();

                        if(refreshResp === true) {

                            const secondTry = await this.post(servico, payload);

                            return secondTry.data;

                        } else {

                            throw new Error("nao consegui pegar novo token (post)");

                        }

                    }

                }

                return data;

            } catch (error) {

                loadingFn(false);

                throw error;

            }

        },

        fakePostJSON : async function({acaoWebAExecutar, sistema, projeto, rest, headers = { }}, payload) {

            loadingFn(true);

            await delay(1000 * 1);

            loadingFn(false);

            return payload.fakeData;

        },

        postJSON: async function({acaoWebAExecutar, sistema, projeto, rest, headers = { }}, payload) {

            const _headers = {
                ...headers
            };

            const agora = new Date();

            const servico = ((rest === false)
                ? "/" + sistema + "/" + projeto + "." + acaoWebAExecutar + ".smk?TXCONFIG=" + appConfig.txConfig
                : "/" + sistema + "/rest/" + projeto + "/" + appConfig.txConfig + "/" + acaoWebAExecutar + "/plain?TXCONFIG=" + appConfig.txConfig)
                + "&lm=" + agora.getTime();

            if(rest === false) {

                payload.CP = userSession.txCP;

            } else {

                _headers["iy2b-post"] = "S";

            }

            payload.txIdApp = appConfig.txIdApp;

            if(vDebugLigado === true) {
                payload.debugLigado = "S";
                if(cacheProjetos[sistema] === null || cacheProjetos[sistema] === undefined) cacheProjetos[sistema] = { };
                cacheProjetos[sistema][projeto] = true;
            }

            return this.post(servico, transformData(payload), _headers);

        },

        post: async function(servico, payload, headers) {

            const config = {
                headers: {
                    "CP": userSession.txCP,
                    "html5-agent": "S",
                    "jqsSMK": "S",
                    ...headers
                }
            };

            try {

                loadingFn(true);

                const response = await api.post(servico, payload, config);

                loadingFn(false);

                const data = response.data;

                if(data.erro !== true) {

                    // console.log(data);

                } else {

                    if(data.key === "invalidSession") {

                        const refreshResp = await this.refreshSession();

                        if(refreshResp === true) {

                            const secondTry = await this.post(servico, payload);

                            return secondTry.data;

                        } else {

                            throw new Error("nao consegui pegar novo token (post)");

                        }

                    }

                }

                return data;

            } catch (error) {

                loadingFn(false);

                throw error;

            }

        },

        upload: async function({acaoWebAExecutar, sistema, projeto, rest=false, habilitaEnvioASP="N", headers={ }}, payload) {

            const _headers = {
                'content-type': 'multipart/form-data',
                plainResult: "S",
                RetornoBinario: "N",
                uploadHttpSMK: "S",
                ...headers
            };

            const agora = new Date();

            const servico = ((rest === false)
                    ? "/" + sistema + "/" + projeto + "." + acaoWebAExecutar + ".smk?TXCONFIG=" + appConfig.txConfig
                    : "/" + sistema + "/rest/" + projeto + "/" + appConfig.txConfig + "/" + acaoWebAExecutar + "/plain?TXCONFIG=" + appConfig.txConfig)
                + "&lm=" + agora.getTime()
                + "&habilitaEnvioASP=" + habilitaEnvioASP
            ;

            if(rest === false) {

                payload.CP = userSession.txCP;

            } else {

                _headers["iy2b-post"] = "S";

            }

            payload.txIdApp = appConfig.txIdApp;

            const formData = new FormData();

            Object.keys(payload).forEach(key => {
                formData.append(key, payload[key]);
            });

            return this.post(servico, formData, _headers);

        }

    }

}

const api = new RequestAPI();

const apiWebM = new RequestAPI();

const useRequestApi = () => {

    const [loading, setLoading] = useState(false);

    const [newApi, setNewApi] = useState (null);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa useRequestApi");

        setNewApi( api.newRequestApi(setLoading) );

        return () => {

            console.log("final useEffect inicializa useRequestApi");

        };

    }, []);

    return [{

        postWS: async (config, payload, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.postWS(config, payload), trackArea ) );
        },

        postJSON: async (config, payload, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.postJSON(config, payload), trackArea ) );
        },

        fakePostJSON: async (config, payload, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.fakePostJSON(config, payload), trackArea ) );
        },

        upload: async (config, payload, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.upload(config, payload), trackArea ) );
        },

        post: async (servico, payload, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.post(servico, payload), trackArea ) );
        },

        get: async (servico, trackArea) => {
            return await trackUnauthorized ( trackPromise ( newApi.get(servico), trackArea ) );
        }

    }, loading];

}

const WORKSPACE_LOADING_INDICATOR_AREA = "workspace-area";

export {
    useRequestApi
}

export function useStore (value) {
    api.setStore(value);
    apiWebM.setStore(value);
}

export function useAppConfig (value) {
    api.setAppConfig(value);
    const webmUrl = (value.txConfig === "desenv")?"http://producao.webmakers.inf.br:8082":"https://producao.webmakers.inf.br:8443";
    const webMConfig = {
        ...value,
        host: webmUrl,
        txConfig: "producao",
        wsToken: "823548236543654"
    }
    apiWebM.setAppConfig(webMConfig);
}

export function useUserSession (value) {
    api.setSession(value);
    apiWebM.setSession(value);
}

export function setDebugLigado(value) {
    api.debugLigado(value);
}

export function debugLigado() {
    return api.debugLigado();
}

export function getProjetosUtilizados() {
    return api.getProjetosUtilizados();
}

export async function postWS (config, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.postWS(config, payload), trackArea ) );
}

export async function postWSWebmakers (config, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( apiWebM.postWS(config, payload), trackArea ) );
}

export async function postJSON (config, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.postJSON(config, payload), trackArea ) );
}

export async function upload (config, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.upload(config, payload), trackArea ) );
}

export async function post (servico, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.post(servico, payload), trackArea ) );
}

export async function get (servico, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.get(servico), trackArea ) );
}

export async function directGet (url, trackArea) {
    return await trackUnauthorized ( trackPromise ( axios.get(url), trackArea ) );
}

export async function fakeRequest (secs, trackArea) {
    return await trackUnauthorized ( trackPromise( delay(1000 * secs), trackArea ) );
}

export async function fakePostJSON (config, payload, trackArea) {
    return await trackUnauthorized ( trackPromise ( api.fakePostJSON(config, payload), trackArea ) );
}
