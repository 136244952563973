import BotaoGoogle from "./BotaoGoogle/botaoGoogle";
import BotaoApple from "./BotaoApple/botaoApple";
import BotaoFacebook from "./BotaoFacebook/botaoFacebook";
import BotaoTwitter from "./BotaoTwitter/botaoTwitter";

const BotaoProvedorIdentidade = (props) => {

    const { onClickVincular, onClickReVincular, onClickDesvincular, block=false, cdProvedorIdentidade, jaVinculado, labelLogin=false, disabled=false } = props;

    let botao = null;

    if(cdProvedorIdentidade===1) {

        botao = <BotaoGoogle
            onClickVincular={onClickVincular}
            onClickReVincular={onClickReVincular}
            block={block}
            cdProvedorIdentidade={cdProvedorIdentidade}
            jaVinculado={jaVinculado}
            labelLogin={labelLogin}
            disabled={disabled}
            onClickDesvincular={onClickDesvincular}
        />;

    } else if(cdProvedorIdentidade===2) {

        botao = <BotaoApple
            onClickVincular={onClickVincular}
            onClickReVincular={onClickReVincular}
            block={block}
            cdProvedorIdentidade={cdProvedorIdentidade}
            jaVinculado={jaVinculado}
            labelLogin={labelLogin}
            disabled={disabled}
            onClickDesvincular={onClickDesvincular}
        />;

    } else if(cdProvedorIdentidade===3) {

        botao = <BotaoFacebook
            onClickVincular={onClickVincular}
            onClickReVincular={onClickReVincular}
            block={block}
            cdProvedorIdentidade={cdProvedorIdentidade}
            jaVinculado={jaVinculado}
            labelLogin={labelLogin}
            disabled={disabled}
            onClickDesvincular={onClickDesvincular}
        />;

    } else if(cdProvedorIdentidade===4) {

        botao = <BotaoTwitter
            onClickVincular={onClickVincular}
            onClickReVincular={onClickReVincular}
            block={block}
            cdProvedorIdentidade={cdProvedorIdentidade}
            jaVinculado={jaVinculado}
            labelLogin={labelLogin}
            disabled={disabled}
            onClickDesvincular={onClickDesvincular}
        />;

    }

    return botao;

}

export default BotaoProvedorIdentidade;
