//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './pesquisaWS.scss';

import React, {useState, useEffect, useImperativeHandle} from 'react';
import {AutoComplete, Input, Tooltip, Col} from "antd";
import {setActive} from "../../../reducer/transacaoManager";
import {connect} from "react-redux";
import {postJSON, postWS} from "../../../lib/RequestAPI";
import {notificacaoErroRequest, useWindowSize} from "../../../lib/iy2b-react";
import {notificacao} from "../../../lib/Notificacao";
import PesquisaWSLoadingIndicator from "../../../componentes/LoadingIndicator/PesquisaWSLoadingIndicator";
import {CUSTOM} from "../../../assets/iconografia";

const PESQUISA_WS_LOADING_INDICATOR_AREA = "header_pesquisaws-area";


const PesquisaWS = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            exibeDropdown: () => false,
            escondeDropdown: () => false,
        };
    });

    const { opcoesMenu, opcoesWizard, onClickWizard } = props ;

    const [options, setOptions] = useState([]);

    const windowSize = useWindowSize();

    useEffect(() => {

        return () => {

        };

    }, []);

    useEffect(() => {

        console.log("opcoesMenu", opcoesMenu);

        if(opcoesMenu.isEmpty() === false) {

            pesquisarProcessos().then(resultado => {
                console.log("pesquisarProcessos", resultado, options);
            });

        }

        return () => {

        };

    }, [opcoesMenu]);

    useEffect(() => {

        // console.log("PesquisaWS.opcoesWizard", options, opcoesWizard);

        if(options.isEmpty() === true) return;

        const novasOpcoesWizard = opcoesWizard.map(item => {

            return {
                key: "W" + item.idWizard,
                label: item.noWizard,
                value: item.noWizard,
                data: {...item},
                type: "wiz"
            }

        });

        const dp = [];

        dp.addAll(options);

        if(novasOpcoesWizard.isEmpty() === true) {
            if(dp[0].key === "wizard") {
                dp.shift();
            }
        } else {
            if(dp[0].key === "wizard") {
                const itemWizard = dp[0];
                itemWizard.options = [];
                itemWizard.options.addAll(novasOpcoesWizard);
            } else {
                const itemWizard = {
                    key: "wizard",
                    label: "Assistente desta operação",
                    options: novasOpcoesWizard
                };
                dp.unshift(itemWizard);
            }
        }

        console.log("novasOpcoesWizard", dp);

        setOptions(dp);

        return () => {

        };

    }, [opcoesWizard]);

    const pesquisarProcessos = async () => {

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mchklstprocesso"
            }, {
                "acaoAExecutar": "pesquisaDropDown"
            }, PESQUISA_WS_LOADING_INDICATOR_AREA);

            const dpGrupos = [];
            const dpCheckL = [];

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaChecklistProcesso") {

                    const dp = msg.lista.map(item => {
                        return {
                            value: item.cdChecklist,
                            label: item.noChecklist,
                            data: {...item}
                        }
                    });

                    dpCheckL.addAll(dp);

                } else if(msg.id === "listaGrupoChecklistProcesso") {

                    const dp = msg.lista.map(item => {
                        return {
                            value: item.cdGrupoChecklist,
                            label: item.noGrupoChecklist,
                            data: {...item}
                        }
                    });

                    dpGrupos.addAll(dp);

                } else {

                    console.log(msg);

                }

            });

            const dp = dpGrupos.map(item => {
                return {
                    key: item.value,
                    label: item.label,
                    options: dpCheckL.filter(checkl => checkl.data.cdGrupoChecklist === item.value).map(checkl => {
                        return {
                            key: checkl.data.cdGrupoChecklist + "," + checkl.value,
                            label: checkl.label,
                            value: checkl.label,
                            data: checkl.data,
                            type: "chkl"
                        }
                    })
                }
            });

            const itensMenu = [];

            const adicionaTrn = ( trn ) => {
                if(trn.tpTransacao === "M") {
                    trn.childs?.map (child => adicionaTrn ( child ));
                } else {
                    itensMenu.push({
                        key: "T" + trn.key,
                        label: trn.title,
                        value: trn.title,
                        data: trn,
                        type: "trn"
                    });
                }
                return true;
            }

            opcoesMenu
                .filter(trn => (trn.tpTransacao === "M"))
                .map(trn => adicionaTrn ( trn ))
            ;

            const itemTransacoes = {
                key: "transacoes",
                label: "Operações da plataforma",
                options: itensMenu
            };

            dp.unshift(itemTransacoes);

            if(opcoesWizard.isEmpty() === false) {

                const novasOpcoesWizard = opcoesWizard.map(item => {
                    return {
                        key: "W" + item.idWizard,
                        label: item.noWizard,
                        value: item.noWizard,
                        data: {...item},
                        type: "wiz"
                    }
                });

                const itemWizard = {
                    key: "wizard",
                    label: "Assistente desta operação",
                    options: novasOpcoesWizard
                };

                dp.unshift(itemWizard);

            }

            // console.log(dp);

            setOptions(dp);

            if(dp.isEmpty() === true) {
                notificacao.aviso({message:"Atenção", description:"Funcionalidade em desenvolvimento"});
            }

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e);

            return false;

        }

    }

    const logAuditoria = (cdTransacao) => {
        const w = window.innerWidth;
        const h = window.innerHeight;
        const uAgent = navigator.userAgent.trim() + "; screen/" + w + "," + h + "; touch/" + window.isTouchDevice();
        const versaoEmExec = parseInt(process.env.REACT_APP_VERSAO_ATUAL);
        const payload = {
            cdPessoaUsr: props.userSession.cdPessoaUsr,
            cdPessoaEmp: props.userSession.cdPessoaEmpAtual,
            cdTransacao: cdTransacao,
            txIdApp: "townvillage",
            appVersion: versaoEmExec,
            client: uAgent,
        };
        postWS({
            sistema: "smk",
            projeto: "msmkpiy2b",
            acaoWebAExecutar: "processarNegocio",
            acaoAExecutar: "incluir"
        }, payload).then(resposta => {
            resposta.mensagens.forEach(msg => {
                console.log(msg);
            });
        }, error => {
            console.log(error);
        });
    }

    const [valueAutoComplete, setValueAutoComplete] = useState(null);

    const onAutcompleteChange = (value) => {
        setValueAutoComplete(value);
    }

    const onAutocompleteSelect = (value, option) => {
        setValueAutoComplete(value);
        if(value === null) return;
        //console.log("onAutocompleteSelect", e.key.substring(1));
        //props.setActive(e.key.substring(1));
        if(option.type==="trn") {
            props.setActive(option.key.substring(1)).then(r => {
                logAuditoria( parseInt(option.key.substring(1)) );
            });
        } else if(option.type==="wiz") {
            onClickWizard(option.data);
        } else {
            props.onVerCheckList(option.data);
        }
        setTimeout(() => {
            setValueAutoComplete(null);
        }, 1000 * 1)
    };

    const acPlaceHolder =(options.isEmpty() === true) ? "Aguarde ..." : "Digite o auxílio que você deseja";


    return (
        <Tooltip placement="bottom" title={"Pesquise e encontre"}>
            <Col id={"containerPesquisaWS"} className={"full-height"}>
                <PesquisaWSLoadingIndicator area={PESQUISA_WS_LOADING_INDICATOR_AREA} />
                <div id={"boxPesquisaWS"}>
                    <AutoComplete id={"acBotaoPesquisaWS"}
                                  popupClassName={"dropdownSearchBotaoPesquisaWS"}
                                  popupMatchSelectWidth={500}
                                  style={{ flex: "auto", margin: "12px" }}
                                  options={options}
                                  value={valueAutoComplete}
                                  onChange={onAutcompleteChange}
                                  onSelect={onAutocompleteSelect}
                                  filterOption={(input, option) => {
                                      if(option.hasOwnProperty("options")) {
                                          return false;
                                      } else {
                                          return option.value.toLowerCase().retiraAcentos().indexOf(input.toLowerCase().retiraAcentos()) >= 0;
                                      }
                                  }}
                    >
                        <Input.Search size="large" placeholder={acPlaceHolder} allowClear enterButton={CUSTOM.SEARCH_ICON}/>
                    </AutoComplete>
                </div>
            </Col>
        </Tooltip>
    );

});

const mapStateToProps = function( state ) {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActive: async (key) => {
            return dispatch( setActive(key) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null,{forwardRef: true})(PesquisaWS);

export {
    PESQUISA_WS_LOADING_INDICATOR_AREA
}
