export class SerializadorEventos {

    constructor () {

        this._finalizado = false;

        this.REQUISICOES_CONCLUIDAS = {};

        this.callback = () => {

        };

    }

    setCallback = (jsBlock) => {

        this.callback = jsBlock;

    };

    adicionaDependencia = (id) => {

        this.REQUISICOES_CONCLUIDAS[id] = false;

    };

    removeDependencia = (id) => {

        delete this.REQUISICOES_CONCLUIDAS[id];

    };

    concluiDependencia = (id) => {

        this.REQUISICOES_CONCLUIDAS[id] = true;

        this.broadcast();

    };

    finalizado = (value) => {

        if(value == null || value == undefined) {
            return this._finalizado;
        } else {
            this._finalizado = (value==true);
        }

    };

    broadcast = () => {

        if(this._finalizado) return;

        let concluido = true;

        for(let index in this.REQUISICOES_CONCLUIDAS) {

            let loaded = this.REQUISICOES_CONCLUIDAS[index];

            if(!loaded) {
                concluido = false;
            }

        }

        if (concluido) {
            this.callback();
        }

    };

};
