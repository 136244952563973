const eventBus = {
    on(event, callback) {
        console.log("adding listner " + event);
        const realcb = (e) => callback(e.detail);
        document.addEventListener(event, realcb);
        return realcb;
    },
    dispatch(event, data) {
        console.log("sending event " + event);
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    }
};

export default eventBus;
