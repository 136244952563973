
import React, {useState} from 'react';
import {useHotkeys} from "react-hotkeys-hook";
import {desligaDebug, isDebugLigado, ligaDebug} from "../reducer/suite";
import {connect} from "react-redux";
import SenhaDebug from "./SenhaDebug/senhaDebug";
import VerDebug from "./VerDebug/verDebug";
import {getSession} from "../reducer/userSession";

const IY2BHotKeys = ( props ) => {

    const [exibeLigarDebug, setExibeLigarDebug] = useState(false);

    const [exibeVerDebug, setExibeVerDebug] = useState(false);

    const [noLogin, setNoLogin] = useState();

    useHotkeys('shift+alt+d+l', async () => {
        const suite = await props.isDebugLigado();
        if(suite.debugHabilitado === false) {
            setExibeLigarDebug(true);
        }
    });

    useHotkeys('shift+alt+d+k', async () => {
        const suite = await props.isDebugLigado();
        if(suite.debugHabilitado === true) {
            props.desligaDebug();
        }
    });

    const ligarDebug = async () => {
        props.ligaDebug();
    }

    useHotkeys('shift+alt+d+o', async () => {
        const suite = await props.isDebugLigado();
        if(suite.debugHabilitado === true) {
            const session = await props.currentSession();
            setNoLogin(session?.noLogin?.trim().toLowerCase());
            setExibeVerDebug(true);
        }
    });

    return (
        <>
            {React.cloneElement(props.children)}
            <SenhaDebug
                exibePopUp={exibeLigarDebug}
                onRequestClose={async () => {
                    setExibeLigarDebug(false);
                }}
                onLigarDebug={async () => {
                    ligarDebug();
                    setExibeLigarDebug(false);
                }}
            />
            <VerDebug
                noLogin={noLogin}
                exibePopUp={exibeVerDebug}
                onRequestClose={async () => {
                    setExibeVerDebug(false);
                }}
            />
        </>
    )
}

const mapStateToProps = function( { suite } ) {
    return {
        suite: suite,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isDebugLigado: () => {
            return dispatch( isDebugLigado() );
        },
        ligaDebug: () => {
            return dispatch( ligaDebug() );
        },
        desligaDebug: () => {
            return dispatch( desligaDebug() );
        },
        currentSession: () => {
            return dispatch( getSession() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IY2BHotKeys);
