import {useState} from "react";
import {useStateRef} from "./iy2b-react";

export const useFormState = (initial) => {

    const [state, setState, refState] = useStateRef(initial||{ });

    return {
        clear: (newValue) => {
            setState(newValue || { });
        },
        set:(attr, value) => {
            const newState = {
                ...refState.current,
            }
            newState[attr] = value;
            setState(newState);
//            console.log(newState, attr, value)
        },
        get: (attr) => {
            return refState.current[attr];
        }
    }

}
